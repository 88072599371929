import { ReactElement } from "react";
import clsx from "clsx";

type Props = {
  size?: number;
  color?: string;
  className?: string;
};

export function LoadingRing({ size = 100, color = "#ffffff", className }: Props): ReactElement {
  return (
    <div className={clsx("lds-ring", className)}>
      <div />
      <div />
      <div />
      <div />

      <style>{`
        .lds-ring {
          display: inline-block;
          position: relative;
          width: ${size}px;
          height: ${size}px;
        }
        .lds-ring div {
          box-sizing: border-box;
          display: block;
          position: absolute;
          width: ${size}px;
          height: ${size}px;
          border: 2px solid ${color};
          border-radius: 50%;
          animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          border-color: ${color} transparent transparent transparent;
        }
        .lds-ring div:nth-child(1) {
          animation-delay: -0.45s;
        }
        .lds-ring div:nth-child(2) {
          animation-delay: -0.3s;
        }
        .lds-ring div:nth-child(3) {
          animation-delay: -0.15s;
        }
        @keyframes lds-ring {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      `}</style>
    </div>
  );
}
