import { AxiosResponse } from "axios";

import { httpWisppointTestnet } from "./http";

function getWisppointData(address: string): Promise<AxiosResponse> {
  return httpWisppointTestnet.get(`/api/users/wallet-addresses/${address}/points`);
}

function reloadWisppointData(address: string): Promise<AxiosResponse> {
  return httpWisppointTestnet.get(`/api/users/wallet-addresses/${address}/refresh`);
}

const WisppointAPI = {
  getWisppointData,
  reloadWisppointData,
};

export default WisppointAPI;
