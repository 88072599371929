import * as React from "react";

type Styles = {
  popper: React.CSSProperties;
  arrow: React.CSSProperties;
};

type Attributes = {
  popper:
    | {
        [key: string]: string;
      }
    | undefined;
};

export type TooltipContextValue = {
  isShown: boolean;
  show: () => void;
  hide: () => void;
  setTooltipButton: (element: HTMLElement | null) => void;
  setContentElement: (element: HTMLElement | null) => void;
  setArrowElement: (element: HTMLElement | null) => void;
  styles: Styles | undefined;
  attributes: Attributes | undefined;
};

export const TooltipContext = React.createContext<TooltipContextValue>({
  setTooltipButton: () => {},
  show: () => {},
  hide: () => {},
  isShown: false,
  setContentElement: () => {},
  setArrowElement: () => {},
  styles: undefined,
  attributes: undefined,
});
