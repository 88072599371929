import { ReactElement } from "react";

import { IconProps } from "./type";

export function CheckIcon(props: IconProps): ReactElement<IconProps> {
  return (
    <svg fill="none" height="32" viewBox="0 0 32 32" width="32" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M13.3335 20.2295L25.5895 7.97217L27.4761 9.8575L13.3335 24.0002L4.84814 15.5148L6.73348 13.6295L13.3335 20.2295Z"
        fill="currentColor"
      />
    </svg>
  );
}
