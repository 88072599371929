import * as React from "react";
import { ExtendableComponentProps } from "types/overrideableComponent";

import { TooltipContext } from "./contexts/tooltipContext";

type Props = ExtendableComponentProps<"div">;

export function TooltipContent({ children, onClick }: Props): React.ReactElement<Props> {
  const { isShown, setContentElement, setArrowElement, styles, attributes, show, hide } =
    React.useContext(TooltipContext);
  return (
    <>
      {isShown ? (
        <div
          className="tooltip-content relative z-40 max-w-xs rounded-md bg-pGreen-400 bg-btn px-4 py-3 text-pNeutral-800 drop-shadow shadow-pGreen-500 text-xs"
          ref={setContentElement}
          style={styles?.popper}
          onClick={onClick}
          onMouseOut={hide}
          onMouseOver={show}
          {...(attributes?.popper ?? {})}
        >
          {children}

          <div className="arrow" ref={setArrowElement} style={styles?.arrow} />
        </div>
      ) : null}

      <style>{`
        .tooltip-content[data-popper-placement="bottom"] .arrow {
          top: -0.2rem;
        }

        .tooltip-content[data-popper-placement="right"] .arrow {
          left: -0.2rem;
        }

        .tooltip-content[data-popper-placement="top"] .arrow {
          bottom: -0.2rem;
        }

        .arrow,
        .arrow::before {
          width: 0.75rem;
          height: 0.75rem;
          background-color: inherit;
          position: absolute;
        }

        .arrow {
          visibility: hidden;
        }

        .arrow::before {
          content: "";
          transform: rotate(45deg);
          visibility: visible;
          border-radius: 2px;
        }
      `}</style>
    </>
  );
}
