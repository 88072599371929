import { ReactElement } from "react";

import { IconProps } from "./type";

export function TriangleDownIcon(props: IconProps): ReactElement<IconProps> {
  return (
    <svg fill="none" height="32" viewBox="0 0 32 32" width="32" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M16.0003 18.6668L10.667 13.3335H21.3337L16.0003 18.6668Z" fill="currentColor" />
    </svg>
  );
}
