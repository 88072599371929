export function uint8arrayToHex(value: Uint8Array | undefined): string {
  if (!value) return "";
  return value.toString();
}

export function shortenAddress(address: string | undefined, length = 5): string {
  if (!address) {
    return "";
  }
  return `${address.slice(0, length)}...${address.slice(length * -1)}`;
}

export function isValidNumber(value: number | undefined): boolean {
  return typeof value === "number" && !Number.isNaN(value);
}

export function getNumberInputValue(inputNumber: number | undefined): string {
  return isValidNumber(inputNumber) ? `${inputNumber}` : "";
}

export function getIntPart(number: string): string {
  return number.split(".")[0];
}
