import { ReactElement } from "react";

import { IconProps } from "./type";

export function MenuIcon(props: IconProps): ReactElement<IconProps> {
  return (
    <svg fill="none" height="32" viewBox="0 0 32 32" width="32" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M6 7H26V9.42424H6V7ZM11.4545 14.5758H26V17H11.4545V14.5758ZM6 22.1515H26V24.5758H6V22.1515Z"
        fill="currentColor"
      />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_1371_11308"
          x1="6"
          x2="26"
          y1="15.7879"
          y2="15.7879"
        >
          <stop stopColor="#0CE5D5" />
          <stop offset="1" stopColor="#149FD9" />
        </linearGradient>
      </defs>
    </svg>
  );
}
