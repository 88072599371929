import { parseUnits } from "@ethersproject/units";
import { Amount } from "sdk/entities/amount";
import { Coin } from "sdk/entities/coin";

import { trimZeroes } from "./trimZeros";

export function tryParseUnit(value?: string, coin?: Coin): bigint | undefined {
  if (!value || !coin) {
    return undefined;
  }
  const typedValueParsed = parseUnits(trimZeroes(value), coin.decimals).toString();
  if (typedValueParsed !== "0") {
    return BigInt(typedValueParsed);
  }
  return undefined;
}

export function tryParseAmount(value?: string, coin?: Coin): Amount | undefined {
  if (!value || !coin) {
    return undefined;
  }
  const amt = tryParseUnit(value, coin);
  if (amt === undefined) {
    return undefined;
  }
  return Amount.fromRawAmount(coin, amt);
}

export function isTooManyDecimals(value?: string, coin?: Coin): boolean {
  if (!value || !coin) {
    return false;
  }
  const coinDecimals = coin?.decimals;
  let inputDecimals = 0;
  try {
    tryParseUnit(value, coin);
  } catch (error) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((error as any).code === "NUMERIC_FAULT") {
      return true;
    }
    throw error;
  }
  inputDecimals = value.split(".")[1]?.length || 0;
  return inputDecimals > coinDecimals;
}
