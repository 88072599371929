import * as React from "react";
import { OverridableComponentProps } from "types/overrideableComponent";

import { TooltipContext } from "./contexts/tooltipContext";

type Props<C extends React.ElementType> = OverridableComponentProps<C>;

export function TooltipButton<C extends React.ElementType>({
  component,
  children,
  onMouseOver,
  onMouseOut,
  onClick,
  ...rest
}: Props<C>): React.ReactElement<Props<C>> {
  const { setTooltipButton, show, hide } = React.useContext(TooltipContext);
  const Component = component ?? "div";

  function handleMouseOver(event: React.MouseEvent): void {
    if (onMouseOver) {
      onMouseOver(event);
    }
    show();
  }

  function handleMouseOut(event: React.MouseEvent): void {
    if (onMouseOut) {
      onMouseOut(event);
    }
    hide();
  }

  function handleClick(event: React.MouseEvent): void {
    event.stopPropagation();
    if (onClick) {
      onClick(event);
    }
  }

  return (
    <Component
      ref={setTooltipButton}
      onClick={handleClick}
      onMouseOut={handleMouseOut}
      onMouseOver={handleMouseOver}
      {...rest}
    >
      {children}
    </Component>
  );
}
