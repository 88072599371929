import { ReactElement } from "react";
import clsx from "clsx";
import { Coin } from "sdk/entities/coin";

import { AssetLogo } from "./AssetLogo";

export function MultipleAssetLogo({
  assets,
  className,
  itemCls,
}: {
  assets: (Coin | undefined)[];
  className?: string;
  itemCls?: string;
}): ReactElement {
  return (
    <div className={clsx("flex shrink-0 [&>img:not(:first-child)]:-ml-2", className)}>
      {assets.map((a) => (
        <AssetLogo asset={a} className={itemCls} key={a?.type ?? ""} />
      ))}
    </div>
  );
}
