import { ReactElement } from "react";
import clsx from "clsx";
import { Coin } from "sdk/entities/coin";

export function AssetLogo({ asset, className }: { asset: Coin | undefined; className?: string }): ReactElement {
  return (
    <img
      alt={asset?.type}
      className={clsx("w-8 h-8 rounded-full shrink-0", className)}
      src={asset?.metadata?.imageUrl || "/images/coins/default.png"}
    />
  );
}
