import { Fragment, ReactElement } from "react";
import { DiscordIcon } from "components/atoms/icons/DiscordIcon";

const DISCORD_INVITE_LINK = "https://discord.gg/MFqyXF6KBr";

export function InactiveUser(): ReactElement {
  return (
    <Fragment>
      <div className="font-semibold text-2xl text-center">Inactive WispSwap User</div>
      <div className="text-pNeutral-500 text-sm text-center mt-1">
        Please join our community for Retroactive & Airdrop
      </div>
      <ul className="mt-8 space-y-2">
        <li>
          1. Join WispSwap{" "}
          <a
            className="text-pGreen-500 underline hover:text-pGreen-400"
            href={DISCORD_INVITE_LINK}
            rel="noopener noreferrer"
            target="_blank"
          >
            Discord
          </a>
        </li>
        <li>2. Verify user</li>
        <li>
          3. Join channel <code className="text-pGreen-500">#wispswap-point</code>
        </li>
        <li>
          4. Use command <code className="text-pGreen-500">/link-wallet-address</code> to link your Discord Id to your
          address
        </li>
      </ul>
      <div className="mt-5 text-sm text-pNeutral-500">
        <span className="text-orange-600 font-medium mr-1">Note:</span>Your data will be available after 1 hour.
      </div>
      <div className="text-center mt-10 mb-4">
        <a
          className="inline-block text-white hover:text-blue-800"
          href={DISCORD_INVITE_LINK}
          rel="noopener noreferrer"
          target="_blank"
        >
          <DiscordIcon className="w-20 h-20" />
        </a>
      </div>
    </Fragment>
  );
}
